<template>
  <div class="page">
    <ShopNavBar title="话费充值" iconColor="black" />

    <div class="help">
      <div class="row items-center justify-between">
        <div class="help-title">充值须知</div>
        <van-checkbox v-model="checked" icon-size="16px" checked-color="#FE2C2D">已读并同意</van-checkbox>
      </div>
      <div>
        <div class="row q-mt-md">
          <img src="@/assets/img/base/huafei1.png" width="35" height="30" />
          <div class="col q-ml-sm">显示充值中的号码千万不要在其他渠道进行充值！一定要等平台的订单显示充值成功或者充值失败后，再到其他渠道充值！否则造成的损失自负！</div>
        </div>
        <div class="row q-mt-md">
          <img src="@/assets/img/base/huafei2.png" width="35" height="30" />
          <div class="col q-ml-sm">充值后，如果有人冒充官方打电话，一定不要理会，直接挂断电话，谨防上当！充值过程中平台绝对不会有人联系您，否则损失自负！</div>
        </div>
        <div class="row q-mt-md">
          <img src="@/assets/img/base/huafei3.png" width="35" height="30" />
          <div class="col q-ml-sm">主副卡不可以同时充值，如果同时充值造成损失自负！</div>
        </div>
      </div>
    </div>

    <van-notice-bar left-icon="volume-o" :scrollable="false" background="#fff" color="#000">
      <van-swipe vertical class="notice-swipe" :autoplay="3000" :touchable="false" :show-indicators="false">
        <van-swipe-item>150****6259刚充值了话费100.00元</van-swipe-item>
        <van-swipe-item>131****2253刚充值了话费200.00元</van-swipe-item>
        <van-swipe-item>147****7249刚充值了话费500.00元</van-swipe-item>
      </van-swipe>
      <template #left-icon>
        <img src="@/assets/img/base/kuaixun.png" width="75" class="q-mr-sm" />
      </template>
    </van-notice-bar>

    <div class="form q-mt-sm">
      <div class="form-title">充值账号</div>
      <div class="input-wrap q-mt-xs">
        <q-input v-model="text" placeholder="请输入手机号" dense borderless >
          <template v-slot:append>
            <div class="record">充值记录</div>
          </template>
        </q-input>
      </div>
      <div class="tip">
        <q-icon name="error_outline"></q-icon>
        请仔细核对充值账号，充值成功不支持退款!
      </div>
    </div>

    <div class="form q-mt-sm">
      <div class="form-title">全国话费充值(1-72小时到账)</div>
      <div class="amount-list q-mt-md">
        <div class="amount-item" v-for="(item, index) in 6" :key="item">
          <div class="amount-item-box" :class="{'amount-active': index == active}">
            <div>50.00元</div>
            <div style="font-weight: bold;">[$6.92]</div>
            <div class="amount-tag">10%让利</div>
          </div>
          <div class="amount-item-suanli">上链算力(T):938.7</div>
        </div>
      </div>
    </div>

    <div class="btn">立即充值</div>
  </div>
</template>

<script>
import { Checkbox, CheckboxGroup, NoticeBar, Swipe, SwipeItem } from 'vant';
import { ref } from 'vue';

export default {
  components: {
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  setup() {
    return {
      checked: ref(true),
      active: ref(0)
    }
  }
}
</script>

<style scoped>
.page {
  min-height: 100vh;
  padding-bottom: 15px;
  background: rgba(242, 242, 242, 1);
}

.page :deep(.nav-bar-row) {
  background: #fff;
}

.page :deep(.title) {
  color: #000;
}

.help {
  padding: 12px 11px;
  background-color: rgba(255, 237, 237, 1);
}

.help-title {
  font-weight: bold;
  font-size: 16px;
}

.notice-swipe {
  height: 40px;
  line-height: 40px;
}

.form {
  padding: 15px 10px;
  background-color: #fff;
}

.form-title {
  font-weight: bold;
  font-size: 15px;
}

.record {
  padding: 0 6px;
  color: #FE2C2D;
  font-size: 14px;
  background-color: #FFD9D9;
}

.input-wrap {
  border-bottom: 1px solid rgba(196, 188, 188, .6);
}

.tip {
  margin-top: 15px;
  padding: 6px 15px;
  color: #FE2C2D;
  border-radius: 99px;
  background-color: #FFEFDE;
}

.amount-list {
  padding-bottom: 10px;
  display: flex;
  overflow-x: auto;
}

.amount-item {
  margin-right: 10px;
  text-align: center;
}

.amount-item-box {
  position: relative;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #FE2C2D;
  font-size: 24px;
  color: #FE2C2D;
  overflow: hidden;
}

.amount-tag {
  width: 90px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  border-radius: 0 0 10px 0;
  background-color: #FE2C2D;
}

.amount-active {
  background-color: #FFECEC;
}

.amount-item-suanli {
  margin-top: 10px;
  padding: 2px 15px;
  border-radius: 99px;
  color: #FE2C2D;
  background-color: #FFECEC;
}

.btn {
  margin: 35px 40px 0;
  height: 44px;
  border-radius: 99px;
  color: #fff;
  text-align: center;
  line-height: 44px;
  background-color: #FE2C2D;
  font-size: 16px;
}
</style>